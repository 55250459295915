export default [
    {
        path: '/custom/workbench',
        name: 'customDetail',
        component: () => import('@/views/custom/workbench/index.vue'),
    },
    {
        path: '/custom/customProductFlow',
        name: 'customProductFlowDetail',
        component: () => import('@/views/custom/productFlow/index.vue'),
    },
    {
        path: '/custom/productFlow/add',
        name: 'productFlowAdd',
        component: () => import('@/views/custom/productFlow/addPage.vue'),
    }
]
