import axios from 'axios';
// import { Notification, MessageBox, Message } from "element-ui";
import {Notify, Toast} from 'vant';
import store from '@/store';
import errorCode from '@/utils/errorCode';
import {tansParams} from '@/utils/ruoyi';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

export const serverUrl = 'https://pro.yimixiuxiu.com/api';// 正式线上
// export const serverUrl = "http://192.168.0.119:8500/api"; // 局域网
// export const serverUrl = "http://217i6305f1.51mypc.cn/api"; //
// 内网穿透
// export const serverUrl = 'http://127.0.0.1:8500/api'; // 本地
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    // baseURL: process.env.VUE_APP_BASE_API,
    baseURL: serverUrl,
    // 超时
    timeout: 30000,
});

// request拦截器
service.interceptors.request.use(
    (config) => {
        // 是否需要设置 token
        const isToken = (config.headers || {}).isToken === false;
        const token = localStorage.getItem('token');
        config.headers['operPlatform'] = 1;
        if (token && !isToken) {
            config.headers['Authorization'] = 'Bearer ' + token; // 让每个请求携带自定义token 请根据实际情况自行修改
        }

        // get请求映射params参数
        if (config.method === 'get' && config.params) {
            let url = config.url + '?' + tansParams(config.params);
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        }
        return config;
    },
    (error) => {
        console.log(error);
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    (res) => {
        //如果访问的是图形验证码，将相应的uuid保存到本地
        if (res.config.url == '/auth/captchaImage') {
            window.localStorage.setItem('captchaImageUUID', res.headers['uuid']);
        }
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息
        const msg = errorCode[code] || res.data.message || errorCode['default'];
        if (code === 401) {
            store.dispatch('LogOut').then(() => {
                location.href = '/login';
            });
            // MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
            //   confirmButtonText: '重新登录',
            //   cancelButtonText: '取消',
            //   type: 'warning',
            // })
            //   .then(() => {
            //     store.dispatch('LogOut').then(() => {
            //       location.href = '/index';
            //     });
            //   })
            //   .catch(() => {});
            // return Promise.reject('令牌验证失败');
        } else if (code === 500) {
            // Message({
            //   message: msg,
            //   type: 'error',
            // });
            Toast.fail(msg);
            return Promise.reject(new Error(msg));
        } else if (code !== 200) {
            // Notification.error({
            //   title: msg,
            // });
            Notify({type: 'danger', message: msg});

            return Promise.reject('error');
        } else {
            return res.data;
        }
    },
    (error) => {
        let {message} = error;
        if (message == 'Network Error') {
            message = '网络错误，建议使用流量或换个地点';
        } else if (message.includes('timeout')) {
            message = '系统接口请求超时';
        } else if (message.includes('Request failed with status code')) {
            message = '系统接口' + message.substr(message.length - 3) + '异常';
        }
        // Message({
        //   message: message,
        //   type: 'error',
        //   duration: 5 * 1000,
        // });
        Toast.fail(message);
        return Promise.reject(error);
    }
);

// 通用下载方法
export function download(url, params, filename) {
    return service
        .post(url, params, {
            transformRequest: [
                (params) => {
                    return tansParams(params);
                },
            ],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob',
        })
        .then((data) => {
            const content = data;
            const blob = new Blob([content]);
            if ('download' in document.createElement('a')) {
                const elink = document.createElement('a');
                elink.download = filename;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href);
                document.body.removeChild(elink);
            } else {
                navigator.msSaveBlob(blob, filename);
            }
        })
        .catch((r) => {
            console.error(r);
        });
}

export default service;
