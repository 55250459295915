export default [
  {
    path: '/crm/clue/add',
    name: 'clueAdd',
    component: () => import('@/views/crm/clue/addPage.vue'),
  },
  {
    path: '/crm/clue/detail',
    name: 'clueDetail',
    component: () => import('@/views/crm/clue/detail.vue'),
  },
  {
    path: '/crm/clue/addFollow',
    name: 'clueAddFollow',
    component: () => import('@/views/crm/clue/addFollow.vue'),
  },

  {
    path: '/crm/customer/add',
    name: 'customerAdd',
    component: () => import('@/views/crm/customer/addPage.vue'),
  },
  {
    path: '/crm/customer/detail',
    name: 'customerDetail',
    component: () => import('@/views/crm/customer/detail.vue'),
  },
  {
    path: '/crm/customer/addFollow',
    name: 'customerAddFollow',
    component: () => import('@/views/crm/customer/addFollow.vue'),
  },

  {
    path: '/crm/cost/add',
    name: 'costAdd',
    component: () => import('@/views/crm/cost/addPage.vue'),
  },
  {
    path: '/crm/cost/detail',
    name: 'costDetail',
    component: () => import('@/views/crm/cost/detail.vue'),
  },
  {
    path: '/crm/cost/addFollow',
    name: 'competeAddFollow',
    component: () => import('@/views/crm/cost/addFollow.vue'),
  },

  {
    path: '/crm/compete/add',
    name: 'competeAdd',
    component: () => import('@/views/crm/compete/addPage.vue'),
  },
  {
    path: '/crm/compete/detail',
    name: 'competeDetail',
    component: () => import('@/views/crm/compete/detail.vue'),
  },

  {
    path: '/crm/contacts/addFollow',
    name: 'contactsAddFollow',
    component: () => import('@/views/crm/contacts/addFollow.vue'),
  },

  {
    path: '/crm/contacts/add',
    name: 'contactsAdd',
    component: () => import('@/views/crm/contacts/addPage.vue'),
  },
  {
    path: '/crm/contacts/detail',
    name: 'contactsDetail',
    component: () => import('@/views/crm/contacts/detail.vue'),
  },

  // {
  //   path: '/crm/team/addFollow',
  //   name: 'teamDetail',
  //   component: () => import('@/views/crm/team/addFollow.vue'),
  // },

  {
    path: '/crm/team/add',
    name: 'teamAdd',
    component: () => import('@/views/crm/team/addPage.vue'),
  },
  // {
  //   path: '/crm/team/detail',
  //   name: 'teamDetail',
  //   component: () => import('@/views/crm/team/detail.vue'),
  // },
  {
    path: '/crm/maintenance/detail',
    name: 'maintenanceDetail',
    component: () => import('@/views/crm/maintenance/detail.vue'),
  },
];
