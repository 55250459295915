<template>
  <div>
    <div class="push-msg-box flex-spa-ver-cen">
      <div class="title-wrap">
        <div class="tit">开启推送通知</div>
        <div class="desc">及时接受工作变动、工作情况等信息</div>
      </div>
      <div class="btn">立即开启</div>
      <van-icon name="cross" class="close-btn" />
    </div>

    <van-cell v-for="(item, index) in list" :key="index">
      <div class="flex-spa-bet item-cell">
        <img :src="require(`../../../assets/images/notice_icon${index + 1}.png`)" alt="" />

        <div class="right-con">
          <div class="cell-title flex-spa-ver-cen">
            <div class="tit">{{ item.name }}</div>
            <div class="time">03-07 15：30</div>
          </div>
          <div class="desc over_hidden">
            {{ item.label }}
          </div>
        </div>
      </div>
    </van-cell>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: '通勤助手',
          label: '通勤助手通勤助手通勤助手通勤助手~',
          imgUrl: 'notice_icon1',
        },
        {
          name: '邮箱',
          label: '邮箱邮箱邮箱邮箱~',
          imgUrl: 'notice_icon2',
        },
        {
          name: '系统公告',
          label: '系统公告系统公告系统公告系统公告系统公告系...',
          imgUrl: 'notice_icon3',
        },
        {
          name: '系统消息',
          label: '系统消息系统消息系统消息',
          imgUrl: 'notice_icon4',
        },
        {
          name: '帮助服务',
          label: '通勤助手通勤助手通勤助手通勤助手通勤',
          imgUrl: 'notice_icon5',
        },
        {
          name: '创建团队',
          label: '创建团队创建团队创建团队',
          imgUrl: 'notice_icon6',
        },
        {
          name: '行业资讯',
          label: '掌握最新行业动态~',
          imgUrl: 'notice_icon7',
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.push-msg-box {
  width: 675px;
  height: 110px;
  background: #f9f9fb;
  border-radius: 4px 4px 4px 4px;
  margin: 0 auto;
  padding: 0 26px;
  .title-wrap {
    .tit {
      font-size: 30px;
      color: #333333;
      font-weight: 800;
    }
    .desc {
      font-size: 24px;
      color: #979797;
      margin-top: 10px;
    }
  }

  .btn {
    width: 136px;
    height: 50px;
    background: #f65248;
    border-radius: 27px 27px 27px 27px;
    line-height: 50px;
    text-align: center;
    font-size: 22px;
    color: #fff;
    cursor: pointer;
  }

  .close-btn {
    font-size: 34px;
    color: #999;
    cursor: pointer;
  }
}

.item-cell {
  padding: 20px 0;

  img {
    width: 80px;
    height: 80px;
  }

  .right-con {
    width: 530px;
    .cell-title {
      padding-top: 10px;
      .tit {
        font-weight: bold;
        color: #333333;
        font-size: 32px;
      }
      .time {
        padding-top: 10px;
        font-size: 20px;
        color: #bbbbbb;
      }
    }
    .desc {
      color: #888;
      font-size: 24px;
      margin-top: 15px;
    }
  }
}
</style>
