import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import plugins from "./plugins"; // plugins
import 'amfe-flexible';
import '@/assets/style/reset.css';
import '@/assets/style/common.scss';
import '@/assets/style/init.css';
import '@/assets/style/iconfont.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import './permission';
import directive from './directive';
import { install } from 'vs-tree';
import 'vs-tree/dist/vs-tree.css';
Vue.use(install);
// vue中监听移动端滑动事件
import vueTouch from 'kim-vue-touch';
Vue.use(vueTouch);

// 日历   在入口文件中（main.js），导入组件库
import vueHashCalendar from 'vue-hash-calendar';
// 引入组件CSS样式
import 'vue-hash-calendar/lib/vue-hash-calendar.css';
// 注册组件库
Vue.use(vueHashCalendar);
Vue.use(plugins);
// 挂载到全局的方法 --start
import { getDicts } from '@/api/system/dict/data';
import { download } from '@/utils/request';
import { objectToFormData } from '@/utils/ruoyi';
Vue.prototype.getDicts = getDicts;
Vue.prototype.download = download;
Vue.prototype.objectToFormData = objectToFormData;
// 挂载到全局的方法 -- end
Vue.config.productionTip = false;
Vue.use(Vant);
Vue.use(directive);
// 常用组件挂载到全局
import navBar from '@/components/navBar/index';
import textAreaInput from '@/components/textarea/index';
Vue.component('navBar', navBar);
Vue.component('textAreaInput', textAreaInput);

// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
