const state = {
  applyIdList: [], // 适用人群ID集合
  ruleSetForm: {},
};

const mutations = {
  SET_APPLY_ID_LIST: (state, applyIdList) => {
    state.applyIdList = applyIdList;
  },

  SET_RULE_SET_FORM: (state, ruleSetForm) => {
    state.ruleSetForm = ruleSetForm;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
