export default [
  {
    path: '/myApply/setting',
    name: 'myApplySet',
    component: () => import('@/views/approve/myApply/setting.vue'),
  },

  {
    path: '/myApply/applyDetail',
    name: 'myApplySetDetail',
    component: () => import('@/views/approve/myApply/applyDetail.vue'),
  },
  // 请假申请
  {
    path: '/myApply/applyLeave',
    name: 'myApplyLeave',
    component: () => import('@/views/approve/myApply/applyLeave.vue'),
  },
  // 外出申请
  {
    path: '/myApply/outApply',
    name: 'OutApply',
    component: () => import('@/views/approve/myApply/outApply.vue'),
  },
  // 出差申请
  {
    path: '/myApply/officialBusinessOutApply',
    name: 'OutApply',
    component: () => import('@/views/approve/myApply/officialBusinessOutApply.vue'),
  },
  // 会议申请
  {
    path: '/myApply/mettingApply',
    name: 'MettingApply',
    component: () => import('@/views/approve/myApply/mettingApply.vue'),
  },
  // 用车申请
  {
    path: '/myApply/useCarApply',
    name: 'UseCarApply',
    component: () => import('@/views/approve/myApply/useCarApply.vue'),
  },
  // 资产申请
  {
    path: '/myApply/assetApply',
    name: 'AssetApply',
    component: () => import('@/views/approve/myApply/assetApply.vue'),
  },
  // 报销申请
  {
    path: '/myApply/reimburseApply',
    name: 'ReimburseApply',
    component: () => import('@/views/approve/myApply/reimburseApply.vue'),
  },

  // 新建审批流程
  {
    path: '/myApply/addProcess',
    name: 'AddProcess',
    component: () => import('@/views/approve/myApply/addProcess.vue'),
  },
];
