import router from './router';
import store from './store';
import {Toast} from 'vant';

const whiteList = ['/login', '/auth-redirect', '/bind', '/register'];

router.beforeEach(async (to, from, next) => {
    if (localStorage.getItem('token')) {
        /* has token*/
        if (to.path == '/') {
            next({path: '/login'});
        } else if (to.path == '/login') {
            const path = localStorage.getItem('indexPath');
            next({path: path});
        } else {
            if (store.getters.roles.length === 0) {
                // 判断当前用户是否已拉取完user_info信息
                store
                    .dispatch('GetInfo')
                    .then(async () => {
                        store.dispatch('GenerateRoutes').then((accessRoutes) => {
                            // 根据roles权限生成可访问的路由表
                            accessRoutes.forEach(res => {
                                router.addRoute(res);// 动态添加可访问路由表
                            })
                            next({...to, replace: true}); // hack方法 确保addRoutes已完成
                        });
                    })
                    .catch((err) => {
                        store.dispatch('LogOut').then(() => {
                            Toast.fail(err);
                            next({path: '/'});
                        });
                    });
            } else {
                next();
            }
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next();
        } else if (to.path == '/') {
            next(`/login`); // 否则全部重定向到登录页
        } else {
            next(`/login`); // 否则全部重定向到登录页
        }
    }
});
