import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/login/index.vue';
import Register from '../views/register/index.vue';
import Attendance from './modules/attendance';
import Notice from '../views/notice/index.vue';
import Crm from './modules/crm';
import OA from './modules/oa';
import Inventory from './modules/inventory';
import Purchase from './modules/purchase';
import Sale from './modules/sale';
import SaleAfter from './modules/saleAfter';
import Cw from './modules/cw';
import Custom from './modules/custom';
import Statement from './modules/statement';
import StaticSystem from './modules/staticSystem';
import MyApply from './modules/myApply';

Vue.use(VueRouter);

export const constantRoutes = [
    // {
    //   path: '/redirect/:path(.*)',
    //   component: (resolve) => require(['@/views/redirect.vue'], resolve),
    // },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },

    {
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/notice',
        name: 'Notice',
        component: Notice,
    },
    ...Crm,
    ...Attendance,
    ...StaticSystem,
    ...MyApply,
    ...OA,
    ...Inventory,
    ...Purchase,
    ...Sale,
    ...SaleAfter,
    ...Cw,
    ...Custom,
    ...Statement
];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: constantRoutes,
    // routes: [...constantRoutes, ...noticeRouterList],
});

export default router;
