export default [
    {
        path: '/statement/workbench',
        name: 'statementDetail',
        component: () => import('@/views/statement/workbench/index.vue'),
    },
    {
        path: '/statement/crmReport',
        name: 'crmReportDetail',
        component: () => import('@/views/statement/crmReport/index.vue'),
    },
    {
        path: '/statement/purchaseReport',
        name: 'purReportDetail',
        component: () => import('@/views/statement/purchaseReport/index.vue'),
    },
    {
        path: '/statement/invooiceReport',
        name: 'invooiceDetail',
        component: () => import('@/views/statement/invooiceReport/index.vue'),
    },

]
