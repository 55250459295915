import request from "@/utils/request";
import {stringToHex} from "@/utils/ruoyi";

// 登录方法
export function login(number, account, password, code, uuid) {
    return request({
        url: "/auth/loginByAccount",
        method: "post",
        data: {number: stringToHex(number), account: stringToHex(account), password: stringToHex(password), code, uuid}
    });
}

// 手机号密码登录
export function phonenumberlogin(phonenumber, password, code, uuid) {
    return request({
        url: "/auth/loginByPhonePwd",
        method: "post",
        data: {phonenumber: stringToHex(phonenumber), password: stringToHex(password), code, uuid}
    });
}

// 手机号验证码登录
export function codelogin(phonenumber, code) {
    return request({
        url: "/auth/loginByPhoneCode",
        method: "post",
        data: {phonenumber: stringToHex(phonenumber), code: stringToHex(code)}
    });
}

// // 注册方法
// export function register(data) {
//   return request({
//     url: "/auth/register",
//     headers: {
//       isToken: false
//     },
//     method: "post",
//     data: data
//   });
// }

// 刷新方法
export function refreshToken() {
    return request({
        url: "/auth/refresh",
        method: "post"
    });
}

// 获取用户详细信息
export function getInfo() {
    return request({
        url: "/system/user/getInfo",
        method: "get"
    });
}

// 退出方法
export function logout() {
    return request({
        url: "/auth/logout",
        method: "delete"
    });
}

// 获取验证码
export function getCodeImg() {
    return request({
        url: "/auth/captchaImage",
        method: "get",
        timeout: 30000,
        responseType: "blob"
    });
}

// 获取手机验证码
export function getauthcode(phonenumber) {
    return request({
        url: `/auth/phoneCode/?phonenumber=${phonenumber}`,
        method: "get"
    });
}

// 注册
export function register(sysRegisterVo) {
    return request({
        url: `/auth/register`,
        method: "POST",
        headers: {
            isToken: false
        },
        data: sysRegisterVo
    });
}
