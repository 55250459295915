export default {
  /**
   * 检验是否是手机号
   */
  isPhone: value => {
    const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    return reg.test(value);
  },

  /**
   * 检验是否是电话
   */
  isTel: value => {
    return this.isPhone(value) || this.isLandline(value);
  },

  /**
   * 检验是否是邮箱
   */
  isEmail: value => {
    const reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
    return reg.test(value);
  },

  /**
   * 检验是否是数字
   */
  isNumber: value => {
    const reg = /^[0-9]+.?[0-9]*/;
    return reg.test(value);
  },

  /****
   * 校验是不是正整数
   */
  isInt: value => {
    const reg = /^\+?[1-9][0-9]*$/;
    return reg.test(value);
  },

  /****
   * 校验密码
   */
  isPassword: value => {
    const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
    return reg.test(value);
  },

  isUrl: value => {
    const reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/|www\.)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~/])+$/;
    return reg.test(value);
  },

  isIp: value => {
    const reg = /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/;
    return reg.test(value);
  },
  //    var reg = /^[A-Fa-f\d]{2}-[A-Fa-f\d]{2}-[A-Fa-f\d]{2}-[A-Fa-f\d]{2}-[A-Fa-f\d]{2}-[A-Fa-f\d]{2}$/;
  isMac: value => {
    var reg = /^[a-f\d]{2}:[a-f\d]{2}:[a-f\d]{2}:[a-f\d]{2}:[a-f\d]{2}:[a-f\d]{2}$/;
    return reg.test(value);
  }
};
