import {constantRoutes} from '@/router';
import {getRouters} from '@/api/menu';
import Layout from '@/layout/index';
// import ParentView from '@/components/ParentView';
// import InnerLink from '@/layout/components/InnerLink';

const permission = {
    state: {
        routes: [],
        addRoutes: [],
        defaultRoutes: [],
        topbarRouters: [],
        sidebarRouters: [],
        headerRouters: [],
    },
    mutations: {
        SET_ROUTES: (state, routes) => {
            state.addRoutes = routes;
            state.routes = constantRoutes.concat(routes);
        },
        SET_DEFAULT_ROUTES: (state, routes) => {
            state.defaultRoutes = constantRoutes.concat(routes);
            state.headerRouters = routes.map((ele) => {
                return {
                    text: ele.meta.title,
                    value: ['统计报表', '功能模块'].indexOf(ele.meta.title) != -1 ? (ele.path + '/workbench') : (ele.children ? `${ele.path}/${ele.children[0].path}` : ele.path),
                    className: 'item-header',
                    children: ele.children
                        ? ele.children.map((item) => {
                            return {
                                title: item.meta.title,
                                path: `${ele.path}/${item.path}`,
                                imgUrl: item.path,
                                children: ['统计报表', '财务管理'].indexOf(ele.meta.title) != -1 && item.children
                                    ? item.children.map((detail) => {
                                        return {
                                            title: detail.meta.title,
                                            path: `${ele.path}/${item.path}/${detail.path}`,
                                            imgUrl: detail.path
                                        }
                                    }) : []
                            };
                        })
                        : [],
                };
            });

            state.headerRouters.forEach((ele) => {
                if (ele.children.length > 0 && ele.text != '统计报表' && ele.text != '功能模块') {
                    ele.children.shift();
                }
            });
        },
        SET_TOPBAR_ROUTES: (state, routes) => {
            // 顶部导航菜单默认添加统计报表栏指向首页
            const index = [];
            state.topbarRouters = routes.concat(index);
        },
        SET_SIDEBAR_ROUTERS: (state, routes) => {
            state.sidebarRouters = routes;
        },

        SET_HEADER_ROUTERS: (state, routes) => {
            state.headerRouters = routes.map((ele) => {
                return {
                    text: ele.meta.title,
                    value: ele.path,
                };
            });
        },
    },
    actions: {
        // 生成路由
        GenerateRoutes({commit}) {
            return new Promise((resolve) => {
                console.log('向后端发送getRouters请求')
                // 向后端请求路由数据
                getRouters().then((res) => {
                    const sdata = JSON.parse(JSON.stringify(res.data));
                    const rdata = JSON.parse(JSON.stringify(res.data));
                    const sidebarRoutes = filterAsyncRouter(sdata);
                    const rewriteRoutes = filterAsyncRouter(rdata, false, true);
                    rewriteRoutes.push({path: '*', redirect: '/404', hidden: true});
                    commit('SET_ROUTES', rewriteRoutes);
                    commit('SET_SIDEBAR_ROUTERS', constantRoutes.concat(sidebarRoutes));
                    commit('SET_DEFAULT_ROUTES', sidebarRoutes);
                    commit('SET_TOPBAR_ROUTES', sidebarRoutes);
                    // commit('SET_HEADER_ROUTERS', sdata);
                    resolve(rewriteRoutes);
                });
            });
        },
    },
};

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
    return asyncRouterMap.filter((route) => {
        if (type && route.children) {
            route.children = filterChildren(route.children);
        }
        if (route.component) {
            // Layout ParentView 组件特殊处理
            if (route.component === 'Layout') {
                if (route.name == 'Statement') {
                    route.component = 'statement/workbench'
                    route.path = '/statement'
                    route.component = loadView(route.component);
                    delete route.redirect
                    delete route.alwaysShow
                } else if (route.name == 'Custom') {
                    route.component = 'custom/workbench'
                    route.path = '/custom'
                    route.component = loadView(route.component);
                    delete route.redirect
                    delete route.alwaysShow
                } else {
                    route.component = Layout;
                }
            } else if (route.component === 'ParentView') {
                if (route.path == 'instock') {
                    route.component = 'inventory/instock'
                    route.component = loadView(route.component);
                } else if (route.path == 'outstock') {
                    route.component = 'inventory/outstock'
                    route.component = loadView(route.component);
                } else if (route.path == 'bank') {
                    route.component = 'cw/bank'
                    route.component = loadView(route.component);
                } else if (route.path == 'cwCollection') {
                    route.component = 'cw/collection'
                    route.component = loadView(route.component);
                } else if (route.path == 'cwPay') {
                    route.component = 'cw/pay'
                    route.component = loadView(route.component);
                } else if (route.path == 'cwInvoice') {
                    route.component = 'cw/invoice'
                    route.component = loadView(route.component);
                } else {
                    route.component = Layout;
                }
            } else if (route.component === 'InnerLink') {
                route.component = Layout;
            } else {
                route.component = loadView(route.component);
            }
        }
        if (route.children != null && route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children, route, type);
        } else {
            delete route['children'];
            delete route['redirect'];
        }
        return true;
    });
}

function filterChildren(childrenMap, lastRouter = false) {
    var children = [];
    childrenMap.forEach((el, index) => {
        if (el.children && el.children.length) {
            if (el.component === 'ParentView') {
                el.children.forEach((c) => {
                    c.path = el.path + '/' + c.path;
                    if (c.children && c.children.length) {
                        children = children.concat(filterChildren(c.children, c));
                        return;
                    }
                    children.push(c);
                });
                return;
            }
        }
        if (lastRouter) {
            el.path = lastRouter.path + '/' + el.path;
        }
        children = children.concat(el);
    });
    return children;
}

export const loadView = (view) => {
    // 路由懒加载
    return (resolve) => require([`@/views/${view}`], resolve);
};

export default permission;
