export default [
    {
        path: '/oa/notice/add',
        name: 'noticeAdd',
        component: () => import('@/views/oa/notice/addPage.vue'),
    },
    {
        path: '/oa/notice/detail',
        name: 'noticeDetail',
        component: () => import('@/views/oa/notice/detail.vue'),
    },
    {
        path: '/oa/work/add',
        name: 'workAdd',
        component: () => import('@/views/oa/work/addPage.vue'),
    },
    {
        path: '/oa/work/detail',
        name: 'workDetail',
        component: () => import('@/views/oa/work/detail.vue'),
    },
    {
        path: '/oa/meeting/add',
        name: 'meetingAdd',
        component: () => import('@/views/oa/meeting/addPage.vue'),
    },
    {
        path: '/oa/meeting/detail',
        name: 'meetingDetail',
        component: () => import('@/views/oa/meeting/detail.vue'),
    },
    {
        path: '/oa/car/add',
        name: 'carAdd',
        component: () => import('@/views/oa/car/addPage.vue'),
    },
    {
        path: '/oa/car/detail',
        name: 'carDetail',
        component: () => import('@/views/oa/car/detail.vue'),
    },
    {
        path: '/oa/assets/add',
        name: 'assetsAdd',
        component: () => import('@/views/oa/assets/addPage.vue'),
    },
    {
        path: '/oa/assets/detail',
        name: 'assetsDetail',
        component: () => import('@/views/oa/assets/detail.vue'),
    },
]
