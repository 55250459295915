<template>
  <div>
    <van-cell v-for="(item, index) in list" :key="index">
      <div class="flex-spa-bet item-cell">
        <img :src="require(`../../../assets/images/notice_icon${index + 1}.png`)" alt="" />

        <div class="right-con">
          <div class="cell-title flex-spa-ver-cen">
            <div class="tit">{{ item.name }}</div>
            <div class="time">03-07 15：30</div>
          </div>
          <div class="desc over_hidden">
            {{ item.label }}
          </div>
        </div>
      </div>
    </van-cell>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: '壹米咻咻群聊',
          label: 'hi，欢迎加入壹米咻咻信息科技有限公司~',
          imgUrl: 'notice_icon1',
        },
        {
          name: '人力-穆甜甜',
          label: '邀请函海报做完了吗？做完发我一份~',
          imgUrl: 'notice_icon2',
        },
        {
          name: 'UI-张雪',
          label: 'QAQ...',
          imgUrl: 'notice_icon3',
        },
        {
          name: '后端-孙涛',
          label: '效果图做的怎么样了？',
          imgUrl: 'notice_icon4',
        },
        {
          name: '帮助服务',
          label: '通勤助手通勤助手通勤助手通勤助手通勤',
          imgUrl: 'notice_icon5',
        },
        {
          name: '创建团队',
          label: '创建团队创建团队创建团队',
          imgUrl: 'notice_icon6',
        },
        {
          name: '行业资讯',
          label: '掌握最新行业动态~',
          imgUrl: 'notice_icon7',
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.item-cell {
  padding: 20px 0;

  img {
    width: 80px;
    height: 80px;
  }

  .right-con {
    width: 530px;
    .cell-title {
      padding-top: 10px;
      .tit {
        font-weight: bold;
        color: #333333;
        font-size: 32px;
      }
      .time {
        padding-top: 10px;
        font-size: 20px;
        color: #bbbbbb;
      }
    }
    .desc {
      color: #888;
      font-size: 24px;
      margin-top: 15px;
    }
  }
}
</style>
