const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  introduction: (state) => state.user.introduction,
  roles: (state) => state.user.roles,
  permissions: (state) => state.user.permissions,
  permission_routes: (state) => state.permission.routes,
  topbarRouters: (state) => state.permission.topbarRouters,
  defaultRoutes: (state) => state.permission.defaultRoutes,
  sidebarRouters: (state) => state.permission.sidebarRouters,
  userId: (state) => state.user.userId,
  formInline: (state) => state.customer.formInline,
  headerRouters: (state) => state.permission.headerRouters,
  clueDetail: (state) => state.customer.clueDetail,
  customerDetail: (state) => state.customer.customerDetail,
  contactsDetail: (state) => state.customer.contactsDetail,
  costDetail: (state) => state.customer.costDetail,
  competeDetail: (state) => state.customer.competeDetail,
  currentLngLat: (state) => state.user.currentLngLat,
};
export default getters;
