export default [
  {
    path: '/attendance',
    name: 'Attendance',
    component: () => import('@/views/attendance/index.vue'),
  },
  // 设置
  {
    path: '/attendance/setting',
    name: 'Setting',
    component: () => import('@/views/attendance/setting.vue'),
  },
  // 规则设置
  {
    path: '/attendance/ruleSet/:id',
    name: 'RuleSet',
    component: () => import('@/views/attendance/ruleSet.vue'),
  },
  // 选择用户
  {
    path: '/attendance/chooseUser',
    name: 'ChooseUser',
    component: () => import('@/views/attendance/chooseUser.vue'),
  },
  // 选择部门
  {
    path: '/attendance/department',
    name: 'Department',
    component: () => import('@/views/attendance/department.vue'),
  },
  // 补卡申请记录
  {
    path: '/attendance/cardRecord',
    name: 'CardRecord',
    component: () => import('@/views/attendance/cardRecord.vue'),
  },

  // 补卡申请记录详情
  {
    path: '/attendance/cardRecordDetail',
    name: 'CardRecordDetail',
    component: () => import('@/views/attendance/cardRecordDetail.vue'),
  },

  // 补卡申请
  {
    path: '/attendance/applyCard',
    name: 'ApplyCard',
    component: () => import('@/views/attendance/applyCard.vue'),
  },

  // 统计
  {
    path: '/attendance/statistics',
    name: 'Statistics',
    component: () => import('@/views/attendance/statistics.vue'),
  },
];
