const state = {
  LevelList: null, // 危险极别,
  natureList: null // 企业性质
};

const mutations = {
  SET_LEVEL_LIST: (state, list) => {
    state.LevelList = list;
  },

  SET_NATURE_LIST: (state, list) => {
    state.natureList = list;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
