export default [
    {
        path: '/purchase/supplier/add',
        name: 'supplierAdd',
        component: () => import('@/views/purchase/supplier/addPage.vue'),
    },
    {
        path: '/purchase/supplier/supplierType',
        name: 'supplierTypeAdd',
        component: () => import('@/views/purchase/supplier/supplierType.vue'),
    },
    {
        path: '/purchase/inquiry/add',
        name: 'inquiryAdd',
        component: () => import('@/views/purchase/inquiry/addPage.vue'),
    },
    {
        path: '/purchase/plan/add',
        name: 'purchasePlanAdd',
        component: () => import('@/views/purchase/plan/addPage.vue'),
    },
    {
        path: '/purchase/order/add',
        name: 'purchaseOrderAdd',
        component: () => import('@/views/purchase/order/addPage.vue'),
    },
    {
        path: '/purchase/payment/add',
        name: 'purchasePaymentAdd',
        component: () => import('@/views/purchase/payment/addPage.vue'),
    },
    {
        path: '/purchase/contract/add',
        name: 'purchaseContractAdd',
        component: () => import('@/views/purchase/contract/addPage.vue'),
    },
    {
        path: '/purchase/return/add',
        name: 'purchaseReturnAdd',
        component: () => import('@/views/purchase/return/addPage.vue'),
    },

]
