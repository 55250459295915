export default [
    {
        path: '/inventory/warehourse/add',
        name: 'warehourseAdd',
        component: () => import('@/views/inventory/warehourse/addPage.vue'),
    },
    {
        path: '/inventory/material/materialType',
        name: 'materialTypeAdd',
        component: () => import('@/views/inventory/material/materialType.vue'),
    },
    {
        path: '/inventory/material/add',
        name: 'materialAdd',
        component: () => import('@/views/inventory/material/addPage.vue'),
    },
    {
        path: '/inventory/instock/add',
        name: 'instockAdd',
        component: () => import('@/views/inventory/instock/addPage.vue'),
    },
    {
        path: '/inventory/outstock/add',
        name: 'outstockAdd',
        component: () => import('@/views/inventory/outstock/addPage.vue'),
    },
    {
        path: '/inventory/experiment/add',
        name: 'experimentAdd',
        component: () => import('@/views/inventory/experiment/addPage.vue'),
    },

]
