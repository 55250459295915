<template>
  <div class="app-container notice-container">
    <van-nav-bar left-arrow @click-left="onClickLeft" @click-right="onClickRight">
      <template #title>
        <div class="tab-wrap flex-hor-ver-cen">
          <div
            :class="[{ 'active-item-tab': currentIndex == 'notice' }, 'item-tab']"
            @click="handleTabChange('notice')"
          >
            通知
          </div>
          <div
            :class="[{ 'active-item-tab': currentIndex == 'message' }, 'item-tab', 'mrg-l-84']"
            @click="handleTabChange('message')"
          >
            消息
          </div>
        </div>
      </template>
      <template #right>
        <van-icon name="setting-o" size="24" />
      </template>
    </van-nav-bar>

    <div class="notice-tab" v-if="currentIndex == 'notice'">
      <Notice />
    </div>
    <div class="message-tab" v-if="currentIndex == 'message'">
      <Message />
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import Notice from './components/Notice.vue';
import Message from './components/Message.vue';
export default {
  components: {
    Notice,
    Message,
  },

  data() {
    return {
      currentIndex: 'notice',
    };
  },

  methods: {
    onClickLeft() {
      this.$router.go('-1');
    },
    onClickRight() {
      Toast('按钮');
    },
    onClick(name, title) {
      Toast(title);
    },

    handleTabChange(key) {
      this.currentIndex = key;
    },
  },
};
</script>

<style lang="scss" scoped>
.notice-container {
  padding: 0 40px;
  background: #fff;
  ::v-deep .van-nav-bar__arrow {
    color: #333;
    font-size: 38px;
    font-weight: bold;
  }
  ::v-deep .van-nav-bar .van-icon {
    color: #333;
  }
}
.tab-wrap {
  background: #fff;
  .item-tab {
    color: #999999;
    font-size: 38px;
    height: 120px;
    line-height: 120px;
  }
  .active-item-tab {
    position: relative;
    color: #000000;
  }
  .active-item-tab::after {
    position: absolute;
    bottom: 0;
    display: inline-block;
    width: 27px;
    height: 6px;
    background: #0091ff;
    border-radius: 5px 5px 5px 5px;
  }
}
::v-deep .van-nav-bar__content {
  height: 120px;
  line-height: 120px;
}
::v-deep .van-hairline--bottom::after {
  display: none;
}
</style>
