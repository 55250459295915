export default [
    {
        path: '/crm/visit/add',
        name: 'crmVisitAdd',
        component: () => import('@/views/crm/visit/addPage.vue'),
    },
    {
        path: '/crm/repair/add',
        name: 'crmRepairAdd',
        component: () => import('@/views/crm/repair/addPage.vue'),
    }

]
