export default [
    {
        path: '/cw/bank',
        name: 'cwBankPage',
        component: () => import('@/views/cw/bank/index.vue'),
    },
    {
        path: '/cw/cwAccount/add',
        name: 'cwAccountAdd',
        component: () => import('@/views/cw/account/addPage.vue'),
    },
    {
        path: '/cw/income/add',
        name: 'cwIncomeAdd',
        component: () => import('@/views/cw/income/addPage.vue'),
    },
    {
        path: '/cw/entry/add',
        name: 'cwEntryAdd',
        component: () => import('@/views/cw/bank/addPageEntry.vue'),
    },
    {
        path: '/cw/expend/add',
        name: 'cwExpendAdd',
        component: () => import('@/views/cw/bank/addPageExpend.vue'),
    },
    {
        path: '/cw/transfer/add',
        name: 'cwTransferAdd',
        component: () => import('@/views/cw/bank/addPageTransfer.vue'),
    },
    {
        path: '/cw/cwCollection',
        name: 'cwCollectionPage',
        component: () => import('@/views/cw/collection/index.vue'),
    },
    {
        path: '/cw/cwPay',
        name: 'cwPayPage',
        component: () => import('@/views/cw/pay/index.vue'),
    },
    {
        path: '/cw/collection/add',
        name: 'cwCollectionAdd',
        component: () => import('@/views/cw/collection/addPageCollection.vue'),
    },
    {
        path: '/cw/return/add',
        name: 'cwPurReturnAdd',
        component: () => import('@/views/cw/collection/addPageReturn.vue'),
    },
    {
        path: '/cw/salePay/add',
        name: 'cwSalePayAdd',
        component: () => import('@/views/cw/pay/addPageSalePay.vue'),
    },
    {
        path: '/cw/purPay/add',
        name: 'cwPurPayAdd',
        component: () => import('@/views/cw/pay/addPagePurPay.vue'),
    },
    {
        path: '/cw/saleReturn/add',
        name: 'cwSaleReturnAdd',
        component: () => import('@/views/cw/pay/addPageSaleReturn.vue'),
    },
    {
        path: '/cw/proPay/add',
        name: 'cwProPayAdd',
        component: () => import('@/views/cw/pay/addPageProPay.vue'),
    },
    {
        path: '/cw/cwInvoice',
        name: 'cwInvoicePage',
        component: () => import('@/views/cw/invoice/index.vue'),
    },
    {
        path: '/cw/invoiceHead/add',
        name: 'cwInvoiceHeadAdd',
        component: () => import('@/views/cw/invoice/addPageInvoiceHead.vue'),
    },
    {
        path: '/cw/crmInvoice/add',
        name: 'cwCrmInvoiceAdd',
        component: () => import('@/views/cw/invoice/addPageCrmInvoice.vue'),
    },
    {
        path: '/cw/purInvoice/add',
        name: 'cwPurInvoiceAdd',
        component: () => import('@/views/cw/invoice/addPagePurInvoice.vue'),
    },
    {
        path: '/cw/proInvoice/add',
        name: 'cwProInvoiceAdd',
        component: () => import('@/views/cw/invoice/addPageProInvoice.vue'),
    },
    {
        path: '/cw/cwEntryInvoice/add',
        name: 'cwEntryInvoiceAdd',
        component: () => import('@/views/cw/invoice/addPageEntryInvoice.vue'),
    },
    {
        path: '/cw/cwExpenditureInvoice/add',
        name: 'cwExpenditureInvoiceAdd',
        component: () => import('@/views/cw/invoice/addPageExpenditureInvoice.vue'),
    },
]
