const state = {
  screen: 1, // 默认我负责是1
  customerId: '',
  customerName: '',
  contactsId: '',
  contactName: '',
  isEmitContactListEvent: false, // 是否触发联系人事件
  isCostListEvent: false, // 是否触发商机事件
  isAgreeListEvent: false, // 是否触发合同事件
  costId: null,
  costName: null,
  productList: [],
  customerList: [], // 客户集合
  formInline: {},
  collectionId: null,
  subList: [],
  clueDetail: {},
  customerDetail: {},
  contactsDetail: {},
  costDetail: {},
  competeDetail: {}
};

const mutations = {
  ADD_CRM_SCREEN: (state, params) => {
    state.screen = params;
  },

  // 客户ID
  SET_CUSTOMER_ID: (state, customerId) => {
    state.customerId = customerId;
  },

  // 客户名称
  SET_CUSTOMER_NAME: (state, customerName) => {
    state.customerName = customerName;
  },

  // 联系人ID
  SET_CONTACTS_ID: (state, contactsId) => {
    state.contactsId = contactsId;
  },

  // 联系人名称
  SET_CONTACTS_NAME: (state, contactName) => {
    state.contactName = contactName;
  },

  // 触发获取联系人事件
  EMIT_CONTACT_LIST_EVENT: (state, isEmitContactListEvent) => {
    state.isEmitContactListEvent = isEmitContactListEvent;
  },

  // 触发商机事件
  EMIT_COST_LIST_EVENT: (state, isCostListEvent) => {
    state.isCostListEvent = isCostListEvent;
  },

  // 触发合同事件
  EMIT_AGREE_LIST_EVENT: (state, isAgreeListEvent) => {
    state.isAgreeListEvent = isAgreeListEvent;
  },

  // 商机ID
  SET_COST_ID: (state, costId) => {
    state.costId = costId;
  },

  // 商机名称
  SET_COST_NAME: (state, costName) => {
    state.costName = costName;
  },

  // 储存商机下的产品
  SET_COST_PRODUCT: (state, productList) => {
    state.productList = productList;
  },

  // 储存客户集合
  SET_ALL_CUSTOMER: (state, customerList) => {
    state.customerList = customerList;
  },

  // 储存商机详情
  SET_COST_FORMINLINE: (state, formInline) => {
    state.formInline = formInline;
  },

  // 储存回款id
  SET_COLLECTION_ID: (state, collectionId) => {
    state.collectionId = collectionId;
  },

  // 回款计划 - 子任务
  SET_COLLETION_SUB_LIST: (state, subList) => {
    state.subList = JSON.parse(subList);
  },

  // 储存付款id
  SET_PAY_ID: (state, payId) => {
    state.payId = payId;
  },

  // 付款计划 - 子任务
  SET_PAY_SUB_LIST: (state, subList) => {
    state.subList = JSON.parse(subList);
  },

  // 保存线索详情对象
  SET_CLUE_DETAIL: (state, clueDetail) => {
    state.clueDetail = JSON.parse(clueDetail);
  },

  // 保存客户详情对象
  SET_CUSTOMER_DETAIL: (state, dataDetail) => {
    state.customerDetail = JSON.parse(dataDetail);
  },

  // 保存联系人详情对象
  SET_CONTACTS_DETAIL: (state, dataDetail) => {
    state.contactsDetail = JSON.parse(dataDetail);
  },

  // 保存商机详情对象
  SET_COST_DETAIL: (state, dataDetail) => {
    state.costDetail = JSON.parse(dataDetail);
  },

  // 保存竞争对手详情对象
  SET_COMPETE_DETAIL: (state, dataDetail) => {
    state.competeDetail = JSON.parse(dataDetail);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
