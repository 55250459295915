<template>
    <div>
        <van-nav-bar left-arrow class="nav-bar" @click-left="handleGoBack" v-show="showNav">
            <template #title>
                <span class="nav-bar-title">{{ title }}</span>
            </template>
            <template #right>
                <slot name="icon1"></slot>
                <slot name="icon2"></slot>
            </template>
        </van-nav-bar>
        <van-notify v-model="show" type="warning">
            <van-icon name="bell" style="margin-right: 4px;" />
            <span>{{ notifyContent }}</span>
        </van-notify>
    </div>
</template>
<script>
    import {outWorkLocation} from "@/api/hr/hrAttendance";
    import { serverUrl } from '@/utils/request'

    export default {
        props: {
            title: String,
            showNav: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                center: {lng: 117.12639941, lat: 36.6565542},
                zoom: 15,
                clockTimer: undefined,
                //定位的次数
                locationFlag: 0,
                //失败最大次数（0是第一个）
                failMaxCount: 3,
                show: false,
                notifyContent: '',
                //websocket对象
                websocketObj: undefined,
                //websocket定时器
                websocketInterval: undefined,
            };
        },

        watch: {
            locationFlag: {
                handler() {
                    if (this.locationFlag > this.failMaxCount) {
                        this.clearTimer('定位循环大于4次，关闭定位定时')
                    }
                },
                deep: true,
                immediate: true,
            }
        },

        destroyed() {
            if (this.clockTimer) {
                this.clearTimer('destroyed销毁，关闭定位定时')
            }
        },

        mounted() {
            //初始化websocket
            this.initWebSocket()

            //保证websocket长连接
            this.webSocketLongConnect()
        },

        methods: {
            handleGoBack() {
                // this.$router.go('-1');
                this.$emit('handleGoBack');
            },

            initWebSocket: function() {
                //设置用户名
                let username = localStorage.getItem('number')
                while (username.length < 7) {
                    username = '0' + username
                }
                username = username + String(localStorage.getItem('userId'))

                //设置url
                let webSocketUrl = '';
                if(serverUrl.indexOf("https") == -1){
                    webSocketUrl = 'ws://'+serverUrl.replace('http://','')+'/system/websocket/' + username
                }else{
                    webSocketUrl = 'wss://'+serverUrl.replace('https://','')+'/system/websocket/' + username
                    // webSocketUrl = 'wss://120.55.69.108:9099/api/system/websocket/' + username
                }

                // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
                this.websocketObj = new WebSocket(webSocketUrl)
                this.websocketObj.onopen = this.websocketonopen
                this.websocketObj.onerror = this.websocketonerror
                this.websocketObj.onmessage = this.websocketonmessage
                this.websocketObj.onclose = this.websocketclose
            },

            websocketonopen: function() {
                console.log('WebSocket连接成功')
            },
            websocketonerror: function() {
                console.log('WebSocket连接发生错误')
            },
            //收到消息
            websocketonmessage: function(e) {
                let resultData = JSON.parse(e.data)

                /*if (resultData.remindType == 'notice') {
                    //公告
                    this.noticelist.splice(0, 0,
                        {
                            'id': resultData.noticeId,
                            'isRead': false,
                            'route': resultData.route,
                            'content': resultData.content,
                            'title': resultData.title,
                            'createTime': resultData.createTime
                        }
                    )
                } else {
                    //通知
                    //去重通知
                    let repeatIndex = 0;
                    for(let i=0;i<this.informlist.length;i++){
                        if(this.informlist[i].content == resultData.content){
                            repeatIndex++;
                            break;
                        }
                    }

                    if(repeatIndex == 0){
                        this.informlist.splice(0, 0, resultData)
                    }

                }*/

                //系统提醒
                this.showNotify(resultData.content)

                /*//只有没激活页面才调用本地通知
                if(!this.pageActivited){
                    //调用Windows本地通知
                    this.windowsNotice(resultData)
                }*/
            },
            websocketclose: function(e) {
                console.log('connection closed')
            },

            //保证websocket长连接
            webSocketLongConnect() {
                this.websocketInterval = setInterval(() => {
                    if(this.websocketObj.readyState != 1){
                        console.log("断开状态，尝试重连");
                        this.initWebSocket();
                    }
                    this.websocketObj.send('ping')
                },550000)
            },

            showNotify(notifyContent) {
                this.notifyContent = notifyContent
                this.show = true;
                setTimeout(() => {
                    this.show = false;
                }, 5000);
            },

            //检测是否外勤定位
            outWorkLocation() {
                if(this.locationFlag>this.failMaxCount){
                    return;
                }
                let that = this
                //检测是否需要外勤定位
                this.clockTimer = setInterval(async () => {
                    let map = new BMap.Map('map');
                    let point = new BMap.Point(this.center);
                    map.centerAndZoom(point, 12);
                    let geolocation = new BMap.Geolocation();
                    // 开启SDK辅助定位
                    geolocation.enableSDKLocation();
                    await geolocation.getCurrentPosition(async function (r) {
                        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                            let mk = new BMap.Marker(r.point);
                            map.addOverlay(mk);
                            map.panTo(r.point);
                            console.log('navBar您的位置：' + r.point.lng + ',' + r.point.lat);
                            let result = await outWorkLocation(r.point.lng + ',' + r.point.lat);
                            if (!result.data) {
                                that.clearTimer('循环，关闭定位定时')
                            }
                        } else {
                            console.log('failed');
                        }
                    }, async function error() {
                        that.locationFlag++;
                        // that.outWorkLocation()
                    })

                }, 20000)
            },

            //清楚定时器
            clearTimer() {
                clearInterval(this.clockTimer);
                this.clockTimer = null;
            }
        }
    };
</script>
<style lang="scss" scoped>
  // 导航样式 --start

  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
