<template>
    <div class="inputarea-wrap">
        <div class="label">{{ title }}</div>
        <textarea
                class="text-input"
                type="text"
                :placeholder="disabled?'':'请输入'"
                v-model="value"
                :disabled="disabled"
                @input="handleChange"
        />
    </div>
</template>
<script>
    export default {
        props: {
            title: {
                type: String,
                default: '内容',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            remark: String,
        },

        model: {
            prop: 'remark',
            event: 'change',
        },

        watch: {
            remark: {
                handler(value) {
                    this.value = value;
                },
                immediate: true,
                deep: true,
            },
        },

        data() {
            return {
                value: null,
            };
        },

        methods: {
            handleChange(e) {
                this.$emit('change', e.target.value);
            },
        },
    };
</script>
<style lang="scss" scoped>
  .inputarea-wrap {
    background: #fff;
    margin: 20px 0;
    padding: 22px 36px;
  }

  .label {
    font-size: 30px;
    color: #111111;
  }

  .text-input {
    width: 100%;
    height: 280px;
    background: #ffffff;
    font-size: 30px;
    padding: 30px 0;
    border: none;
    line-height: 45px;
  }
</style>
