export default [
    {
        path: '/crm/order/add',
        name: 'crmOrderAdd',
        component: () => import('@/views/crm/order/addPage.vue'),
    },
    {
        path: '/crm/invoice/add',
        name: 'crmInvoiceAdd',
        component: () => import('@/views/crm/invoice/addPage.vue'),
    },
    {
        path: '/crm/contract/add',
        name: 'crmContractAdd',
        component: () => import('@/views/crm/contract/addPage.vue'),
    },
    {
        path: '/crm/collection/add',
        name: 'crmCollectionAdd',
        component: () => import('@/views/crm/collection/addPage.vue'),
    },
    {
        path: '/crm/pay/add',
        name: 'crmPayAdd',
        component: () => import('@/views/crm/pay/addPage.vue'),
    },
    {
        path: '/crm/return/add',
        name: 'crmReturnAdd',
        component: () => import('@/views/crm/return/addPage.vue'),
    },
]
