<template>
    <div class="login-wrap">
        <video loop autoplay muted class="video">
            <source
                    src="https://file.yimixiuxiu.com/group1/M00/00/03/rBh9KWKoad6AQ_ARAHBKVF-OwXg244.mp4"
                    type="video/mp4"
            />
        </video>

        <div class="bg"></div>
        <div class="back-btn iconfont icon-xitongfanhui" @click="handleGoBack"></div>
        <!-- 账号登录 -->
        <div class="login-title">
            <div class="label">账号注册</div>
            <div class="desc">欢迎登录壹米咻咻线上办公软件！</div>
        </div>

        <van-form ref="form" class="form" validate-first @failed="onFailed" @submit="onSubmit">
            <!-- 账号登录 -->

            <van-field v-model="companyName" name="companyName" placeholder="请输入公司名称"/>
            <van-field class="first-field" v-model="realname" name="realname" placeholder="请输入姓名"/>

            <van-field
                    class="first-field"
                    v-model="phone"
                    name="phone"
                    placeholder="请输入手机号"
                    :rules="[{ required: true, validator: validatorPhone, message: '请输入正确手机号' }]"
            />

            <van-field
                    class="first-field"
                    v-model="password"
                    name="password"
                    placeholder="请设置密码"
                    :rules="[{ required: true, message: '请设置密码' }]"
            />

            <div class="btn-wrap">
                <van-button block native-type="submit" class="btn" :disabled="registerTitle != '注册'">{{ registerTitle }}</van-button>
            </div>
        </van-form>
        <div class="agree-container">
            <van-checkbox v-model="checked" style="margin-left: 35px;display: inline-block"></van-checkbox>
            点击勾选，即表示同意<span>《隐私政策》</span>和<span>《服务条款》</span>
        </div>
    </div>
</template>
<script>
    import regObj from '@/utils/regex';
    import {register} from '@/api/login';
    import {stringToHex} from "@/utils/ruoyi";

    export default {
        data() {
            return {
                phone: '',
                password: '',
                realname: '',
                companyName: null,
                checked: false,
                registerTitle: '注册'
            };
        },
        methods: {
            validatorPhone(val) {
                return regObj.isPhone(val);
            },

            handleGoBack() {
                this.$router.push('/login');
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            async onSubmit() {
                if (!this.checked) {
                    this.$toast.fail('下方请勾选同意隐私政策和服务条款')
                    return
                }
                this.registerTitle = '正在注册，请稍后...'
                const res = await register({
                    companyName: stringToHex(this.companyName),
                    password: stringToHex(this.password),
                    realname: stringToHex(this.realname),
                    phone: stringToHex(this.phone),
                    equipType: 1,
                });
                if (res.code == 200) {
                    this.$toast(res.message);
                    setTimeout(() => {
                        this.$router.push('/login');
                    }, 2000);
                }
                this.registerTitle = '注册'
            },
        },
    };
</script>
<style lang="scss" scoped>
  .login-wrap {
    background: #000;
    height: 100vh;
    overflow: hidden;
    position: relative;

    .video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      background: rgba(0, 0, 0, 0.2);
    }

    .back-btn {
      position: absolute;
      top: 120px;
      z-index: 3;
      left: 60px;
      font-size: 36px;
      color: #fff;
      cursor: pointer;
    }

    .login-title {
      position: absolute;
      left: 60px;
      top: 200px;
      z-index: 3;
      color: #fff;

      .label {
        font-weight: bold;
        font-size: 44px;
        margin-bottom: 20px;
      }

      .desc {
        font-size: 24px;
        margin-top: 10px;
      }
    }

    .form {
      position: absolute;
      z-index: 3;
      width: 650px;
      left: 50px;
      top: 400px;

      .code-form {
        position: relative;

        .code-btn {
          position: absolute;
          right: 0;
          font-size: 28px;
          color: #fff;
          cursor: pointer;
          bottom: 0.5rem;
        }
      }
    }
  }

  .btn-wrap {
    margin-top: 70px;
  }

  .btn {
    width: 650px;
    height: 90px;
    border-radius: 14px 14px 14px 14px;
  }

  .first-field {
    margin-top: 50px;
  }

  .change-btn {
    width: 650px;
    position: absolute;
    z-index: 3;
    top: 840px;
    left: 50px;
    color: #fff;

    .item-btn {
      text-align: center;
      font-size: 28px;
      cursor: pointer;
    }

    .line {
      margin: 0 20px;
      font-size: 20px;
    }
  }

  .change-btn1 {
    top: 880px;
  }

  .change-btn3 {
    top: 1000px;
  }

  .agree-container {
    position: absolute;
    bottom: 30px;
    width: 100%;

    z-index: 3;
    color: #bbbbbb;
    font-size: 24px;
    text-align: center;

    span {
      display: inline-block;
      color: #54afff;
    }
  }

  ::v-deep .van-cell {
    background: none;
    padding: 30px 15px;
    border-bottom: 1px solid #fff;
  }

  ::v-deep .van-cell::after {
    border-bottom: none;
  }

  ::v-deep .van-field__control {
    color: #fff;
  }

  ::v-deep .van-field--error .van-field__control::placeholder {
    font-size: 0;
  }

  // ::v-deep .van-field__error-message {
  //   margin-top: 20px;
  // }
  ::v-deep .van-field__control::placeholder {
    color: #fff;
  }

  ::v-deep .van-button--normal {
    font-size: 34px;
  }
</style>
