export default [
  {
    path: '/staticSystem/about',
    name: 'About',
    component: () => import('@/views/staticSystem/about/index.vue'),
  },
  {
    path: '/staticSystem/aboutCompany',
    name: 'AboutCompany',
    component: () => import('@/views/staticSystem/about/aboutCompany.vue'),
  },
  {
    path: '/staticSystem/serviceHttps',
    name: 'ServiceHttps',
    component: () => import('@/views/staticSystem/about/serviceHttps.vue'),
  },
  {
    path: '/staticSystem/private',
    name: 'Private',
    component: () => import('@/views/staticSystem/about/private.vue'),
  },
  {
    path: '/staticSystem/msg',
    name: 'MessageList',
    component: () => import('@/views/staticSystem/msg/index.vue'),
  },
  {
    path: '/staticSystem/msg/detail',
    name: 'MessageDetail',
    component: () => import('@/views/staticSystem/msg/detail.vue'),
  },
];
