import request from '@/utils/request';

// 查询当前用户的打卡规则
export function fetchCurrentDaKaRule() {
  return request({
    url: '/hr/checkRule/findByCurrentUser',
    method: 'get',
  });
}

// 查询指定用户打卡记录
export function fetchByUserId(params) {
  return request({
    url: '/hr/checkwork/findByUserId',
    method: 'get',
    params,
  });
}

// 补卡列表
export function fetchReissueCardList(queryParams) {
  const userId = localStorage.getItem('userId');
  return request({
    url: `/hr/checkwork/reissueCardList/${userId}`,
    method: 'get',
    params: queryParams,
  });
}

// 考勤统计-按月份汇总
export function fetchCollectByMonth(params) {
  return request({
    url: `/hr/checkwork/collectByMonth`,
    method: 'get',
    params,
  });
}

// 打卡
export function checkwork(data) {
  return request({
    url: '/hr/checkwork',
    method: 'post',
    data,
  });
}
// 查询规则列表
export function fetchCheckRuleList(params) {
  return request({
    url: '/hr/checkRule/findList',
    method: 'get',
    params,
  });
}

// 删除打卡规则
export function deleteRuleList(data) {
  return request({
    url: '/hr/checkRule/' + data,
    method: 'DELETE',
  });
}

// 查询指定用户打卡记录
export function fetcFindDakaList(params) {
  return request({
    url: '/hr/checkwork/findByUserId',
    method: 'get',
    params,
  });
}

// 根据日期查询用户工时
export function fetcFindWorkTimeByDay(params) {
  return request({
    url: '/hr/checkwork/findWorkTimeByDay',
    method: 'get',
    params,
  });
}

// 考勤统计-按月份汇总(团队)
export function fetchCollectByTeam(params) {
  return request({
    url: `/hr/checkwork/collectByTeam`,
    method: 'get',
    params,
  });
}
// 当前用户当天是否打了下班卡
export function fetchIsClockDown(params) {
  return request({
    url: `/hr/checkwork/isClockDown`,
    method: 'get',
    params,
  });
}
//当前用户当天是否打了上班卡
export function fetchIsClockUp(params) {
  return request({
    url: `/hr/checkwork/isClockUp`,
    method: 'get',
    params,
  });
}
//获取客户端公网ip
export function getIp() {
  return request({
    url: `/system/app/getIp`,
    method: 'get',
  });
}

//外勤定位-根据经纬度获取地点
export function outWorkLocation(lonLat) {
  return request({
    url: `/hr/checkwork/outWorkLocation?lonLat=` + lonLat,
    method: 'post',
  });
}

// 添加考勤规则
export function addCheckRule(data) {
  return request({
    url: '/hr/checkRule',
    method: 'post',
    data,
  });
}

// 编辑考勤规则
export function editCheckRule(data) {
  return request({
    url: '/hr/checkRule',
    method: 'put',
    data,
  });
}

// 查询考勤规则单条
export function fetchCheckRuleDetail(data) {
  return request({
    url: `hr/checkRule/${data}`,
    method: 'get',
  });
}
