import {codelogin, getInfo, login, logout, phonenumberlogin, refreshToken} from '@/api/login';
import {removeToken, setExpiresIn} from '@/utils/auth';
import {Toast} from "vant";

const user = {
    state: {
        token: localStorage.getItem('token'),
        name: '',
        avatar: '',
        roles: [],
        permissions: [],
        userId: null,
        currentLngLat: null,
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_EXPIRES_IN: (state, time) => {
            state.expires_in = time;
        },
        SET_NAME: (state, name) => {
            state.name = name;
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar;
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles;
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions;
        },
        SET_USER_ID: (state, userId) => {
            state.userId = userId;
        },

        SET_CURRENT_LNG_ALT: (state, currentLngLat) => {
            state.currentLngLat = currentLngLat;
        },
    },

    actions: {
        // 登录
        Login({commit}, userInfo) {
            const number = userInfo.number.trim();
            const account = userInfo.account.trim();
            const password = userInfo.password;
            // const code = userInfo.code;
            const uuid = userInfo.uuid;
            return new Promise((resolve, reject) => {
                login(number, account, password, uuid)
                    .then((res) => {
                        let data = res.data;
                        if (data.tempPassCode == 200) {
                            // setToken(data.token);
                            localStorage.setItem('token', res.data.token);
                            // commit('SET_TOKEN', data.token);
                            setExpiresIn(data.expires_in);
                            commit('SET_EXPIRES_IN', data.expires_in);
                            localStorage.setItem('indexPath', data.indexPath);
                            resolve(data.indexPath);
                        } else {
                            Toast.fail(res.message);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        Phonenumberlogin({commit}, userInfo) {
            const phonenumber = userInfo.phonenumber;
            const password = userInfo.password;
            // const code = userInfo.code;
            const uuid = userInfo.uuid;
            return new Promise((resolve, reject) => {
                phonenumberlogin(phonenumber, password, '', uuid)
                    .then((res) => {
                        let data = res.data;
                        if (data.tempPassCode == 200) {
                            localStorage.setItem('token', res.data.token);
                            localStorage.setItem('indexPath', data.indexPath);
                            commit('SET_EXPIRES_IN', data.expires_in);
                            resolve(data.indexPath);
                        } else {
                            Toast.fail(res.message);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        Codelogin({commit}, userInfo) {
            const phonenumber = userInfo.phonenumber.trim();
            const code = userInfo.code;
            return new Promise((resolve, reject) => {
                codelogin(phonenumber, code)
                    .then((res) => {
                        let data = res.data;
                        if (data.tempPassCode == 200) {
                            // setToken(data.token);
                            // commit('SET_TOKEN', data.token);
                            localStorage.setItem('token', res.data.token);
                            setExpiresIn(data.expires_in);
                            commit('SET_EXPIRES_IN', data.expires_in);
                            localStorage.setItem('indexPath', data.indexPath);
                            resolve(data.indexPath);
                        } else {
                            Toast.fail(res.message);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 获取用户信息
        async GetInfo({commit, state}) {
            return new Promise((resolve, reject) => {
                getInfo()
                    .then((res) => {
                        window.localStorage.setItem("deptName", res.user.dept.deptName);
                        window.localStorage.setItem("postName", res.postName);
                        window.localStorage.setItem("postId", res.user.postId);
                        window.localStorage.setItem("userId", res.user.id);
                        window.localStorage.setItem("realname", res.user.realname);
                        window.localStorage.setItem("companyId", res.company.id);
                        window.localStorage.setItem("number", res.company.number);
                        window.localStorage.setItem('isAdmin', res.isAdmin);
                        localStorage.setItem('companyName', res.company.companyName);
                        const user = res.user;
                        const avatar = user.avatar == '' ? require('@/assets/images/profile.jpg') : user.avatar;

                        if (res.roles && res.roles.length > 0) {
                            // 验证返回的roles是否是一个非空数组
                            commit('SET_ROLES', res.roles);
                            commit('SET_PERMISSIONS', res.permissions);
                        } else {
                            commit('SET_ROLES', ['ROLE_DEFAULT']);
                        }
                        commit('SET_NAME', user.userName);
                        commit('SET_USER_ID', user.id);
                        commit('SET_AVATAR', avatar);
                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // 刷新token
        RefreshToken({commit, state}) {
            return new Promise((resolve, reject) => {
                refreshToken(state.token)
                    .then((res) => {
                        setExpiresIn(res.data);
                        commit('SET_EXPIRES_IN', res.data);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // 退出系统
        LogOut({commit, state}) {
            return new Promise((resolve, reject) => {
                logout(state.token)
                    .then(() => {
                        commit('SET_TOKEN', '');
                        commit('SET_ROLES', []);
                        commit('SET_PERMISSIONS', []);

                        removeToken();
                        localStorage.clear();
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // 前端 登出
        FedLogOut({commit}) {
            return new Promise((resolve) => {
                commit('SET_TOKEN', '');
                removeToken();
                resolve();
            });
        }
    }
};

export default user;
