<template>
  <div class="login-wrap">
    <video loop autoplay muted class="video">
      <source
          src="https://file.yimixiuxiu.com/group1/M00/00/03/rBh9KWKoad6AQ_ARAHBKVF-OwXg244.mp4"
          type="video/mp4"
      />
    </video>

    <div class="bg"></div>
    <!-- 手机号登录 -->
    <div class="login-title" v-if="showPhone">
      <div class="label">手机快捷登录</div>
      <div class="desc">手机密码登录，无需验证码</div>
    </div>

    <!-- 验证码登录 -->
    <div class="login-title" v-if="showYzm">
      <div class="label">验证码登录</div>
      <div class="desc">输入手机号验证码，直接登录</div>
    </div>

    <!-- 账号登录 -->
    <div class="login-title" v-if="showAccount">
      <div class="label">账号登录</div>
      <div class="desc">欢迎登录壹米咻咻线上办公软件！</div>
    </div>

    <van-form ref="form" class="form" validate-first @failed="onFailed" @submit="onSubmit">
      <!-- 手机号登录 -->
      <template v-if="showPhone">
        <van-field
            v-model="phonenumber"
            name="手机号"
            placeholder="请输入手机号"
            :rules="[{ required: true, validator: validatorPhone, message: '请输入正确手机号' }]"
        />
        <van-field
            class="first-field"
            v-model="password"
            name="password"
            placeholder="请输入密码"
            :rules="[{ required: true, message: '请输入密码' }]"
        />
      </template>

      <!-- 验证码登录 -->
      <div class="code-form" v-if="showYzm">
        <van-field
            v-model="phonenumber"
            name="phonenumber"
            placeholder="请输入手机号"
            :rules="[{ required: true, validator: validatorPhone, message: '请输入正确手机号' }]"
        />
        <van-field
            class="first-field"
            v-model="code"
            name="code"
            placeholder="请输入验证码"
            :rules="[{ required: true, message: '请输入验证码' }]"
        />
        <div class="code-btn" @click="getCode" v-if="!this.sendCodeStatus">发送验证码</div>
        <div class="code-btn" v-else>{{ codeMsgs }}</div>
      </div>

      <!-- 账号登录 -->
      <template v-if="showAccount">
        <van-field
            v-model="number"
            name="number"
            placeholder="请输入公司编号"
            :rules="[{ required: true, message: '请输入公司编号' }]"
        />
        <van-field
            class="first-field"
            v-model="account"
            name="account"
            placeholder="请输入账号"
            :rules="[{ required: true, message: '请输入账号' }]"
        />
        <van-field
            class="first-field"
            v-model="password"
            name="password"
            placeholder="请输入密码"
            :rules="[{ required: true, message: '请输入密码' }]"
        />
      </template>

      <div class="btn-wrap">
        <van-button block native-type="submit" class="btn" :disabled="loginTitle != '登录'">{{ loginTitle }}
        </van-button>
      </div>
    </van-form>
    <!-- 手机号登录 -->
    <div class="flex-hor-ver-cen change-btn change-btn1" v-if="showPhone">
      <div class="item-btn" @click="handleChange('验证码')">验证码登录</div>
      <div class="line">|</div>
      <div class="item-btn" @click="handleChange('账号')">账号登录</div>
    </div>

    <!-- 验证码登录 -->
    <div class="flex-hor-ver-cen change-btn change-btn1" v-if="showYzm">
      <div class="item-btn" @click="handleChange('手机号')">手机号登录</div>
      <div class="line">|</div>
      <div class="item-btn" @click="handleChange('账号')">账号登录</div>
    </div>

    <!-- 账号登录 -->
    <div class="flex-hor-ver-cen change-btn change-btn3" v-if="showAccount">
      <div class="item-btn" @click="handleChange('手机号')">手机号登录</div>
      <div class="line">|</div>
      <div class="item-btn" @click="handleChange('验证码')">验证码登录</div>
    </div>
    <div class="to-resiter">还没有账号？去 <span @click="handleGoRegister">注册</span></div>
    <div class="agree-container">
      <van-checkbox v-model="checked" style="margin-left: 35px;display: inline-block"></van-checkbox>
      点击勾选，即表示同意<span
        @click="jumpPage('https://www.yimixiuxiu.com/conceal.html')">《隐私政策》</span>和<span
        @click="jumpPage('https://www.yimixiuxiu.com/serviceAgree.html')">《服务条款》</span>
    </div>
  </div>
</template>
<script>
import regObj from '@/utils/regex';
import {getauthcode} from '@/api/login';

export default {
  data() {
    return {
      phonenumber: '',
      password: '',
      account: '',
      number: '',
      code: null,
      showYzm: true,
      showPhone: false,
      showAccount: false,
      codeMsgs: '获取中...',
      countdowns: 60,
      timers: null,
      sendCodeStatus: false,
      checked: false,
      loginTitle: '登录'
    };
  },

  destroyed() {
    if (this.timers) {
      clearInterval(this.timers);
      this.timers = null;
    }
  },

  methods: {
    jumpPage(url) {
      try {
        if (plus) {
          plus.runtime.openURL(url);
        }
      } catch (e) {
        document.location.href = url
      }
    },

    validatorPhone(val) {
      if (val && val.indexOf('123789')) {
        //每个公司的超管不需要验证手机号准确性
        return true;
      }
      return regObj.isPhone(val);
    },

    getCode() {
      // 局部表单验证
      this.$refs.form
          .validate('phonenumber')
          .then(async () => {
            this.sendCodeStatus = true;
            const data = await getauthcode(this.phonenumber);
            if (data.code == 200) {
              if (!this.timers) {
                this.timers = setInterval(() => {
                  if (this.countdowns > 0 && this.countdowns <= 60) {
                    this.countdowns -= 1;
                    if (this.countdowns !== 0) {
                      this.codeMsgs = '重新发送(' + this.countdowns + ')';
                    } else {
                      clearInterval(this.timers);
                      this.codeMsgs = '获取中...';
                      this.countdowns = 60;
                      this.sendCodeStatus = false;
                      this.timers = null;
                    }
                  }
                }, 1000);
              }
            } else {
              this.sendCodeStatus = false;
            }
          })
          .catch(() => {
          });
    },

    handleChange(value) {
      switch (value) {
        case '验证码':
          this.showYzm = true;
          this.showPhone = false;
          this.showAccount = false;
          // 清空输入框
          this.number = null;
          this.account = null;
          this.password = null;
          this.phonenumber = null;
          break;
        case '账号':
          this.showYzm = false;
          this.showPhone = false;
          this.showAccount = true;
          // 清空输入框
          this.number = null;
          this.account = null;
          this.password = null;
          this.phonenumber = null;
          break;
        default:
          this.showYzm = false;
          this.showPhone = true;
          this.showAccount = false;
          // 清空输入框
          this.number = null;
          this.account = null;
          this.password = null;
          this.phonenumber = null;
      }
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

    onSubmit() {
      if (!this.checked) {
        this.$toast.fail('下方请勾选同意隐私政策和服务条款')
        return
      }
      this.loginTitle = '正在登陆，请稍后...'

      if (this.showAccount) {
        // 账号登录
        this.$store
            .dispatch('Login', {
              number: this.number,
              account: this.account,
              password: this.password,
              rememberMe: false,
              uuid: ''
            })
            .then((indexPath) => {
              this.$router.push({path: indexPath || '/index'}).catch((err) => {
                console.log(err, '账号登录报错');
                this.$router.push({path: '/crm/workbench' || '/'}).catch((err) => {
                  console.log(err, '账号登录报错');
                });
              });
            })
            .catch(() => {
              this.loginTitle = '登录'
            });
      } else if (this.showPhone) {
        // 手机号登录
        this.$store
            .dispatch('Phonenumberlogin', {
              password: this.password,
              phonenumber: this.phonenumber
            })
            .then((indexPath) => {
              this.$router.push({path: indexPath || '/'}).catch((err) => {
                console.log(err, '手机号登录失败');
                this.$router.push({path: '/crm/workbench' || '/'}).catch((err) => {
                  console.log(err, '手机号登录失败');
                });
              });
            })
            .catch(() => {
              this.loginTitle = '登录'
            });
      } else {
        this.$store
            .dispatch('Codelogin', {
              phonenumber: this.phonenumber,
              code: this.code
            })
            .then((indexPath) => {
              localStorage.setItem('indexPath', indexPath);
              this.$router.push({path: indexPath || '/'}).catch((err) => {
                console.log(err, '验证码登录失败');
                this.$router.push({path: '/crm/workbench' || '/'}).catch((err) => {
                  console.log(err, '验证码登录失败');
                });
              });
            })
            .catch(() => {
              this.loginTitle = '登录'
            });
      }
    },

    handleGoRegister() {
      this.$router.push('/register');
    },
  },
};
</script>
<style lang="scss" scoped>
.login-wrap {
  background: #000;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.2);
  }

  .login-title {
    position: absolute;
    left: 60px;
    top: 180px;
    z-index: 3;
    color: #fff;

    .label {
      font-weight: bold;
      font-size: 44px;
      margin-bottom: 20px;
    }

    .desc {
      font-size: 24px;
      margin-top: 10px;
    }
  }

  .form {
    position: absolute;
    z-index: 3;
    width: 650px;
    left: 50px;
    top: 400px;

    .code-form {
      position: relative;

      .code-btn {
        position: absolute;
        right: 0;
        font-size: 28px;
        color: #fff;
        cursor: pointer;
        bottom: 0.5rem;
      }
    }
  }
}

.btn-wrap {
  margin-top: 70px;
}

.btn {
  width: 650px;
  height: 90px;
  border-radius: 14px 14px 14px 14px;
}

.first-field {
  margin-top: 50px;
}

.change-btn {
  width: 650px;
  position: absolute;
  z-index: 3;
  top: 840px;
  left: 50px;
  color: #fff;

  .item-btn {
    text-align: center;
    font-size: 28px;
    cursor: pointer;
  }

  .line {
    margin: 0 20px;
    font-size: 20px;
  }
}

.change-btn1 {
  top: 880px;
}

.change-btn3 {
  top: 1000px;
}

.to-resiter {
  position: absolute;
  bottom: 110px;
  width: 100%;
  font-size: 24px;
  text-align: center;
  z-index: 3;
  color: #bbbbbb;

  span {
    display: inline-block;
    color: #54afff;
    cursor: pointer;
  }
}

.agree-container {
  position: absolute;
  bottom: 30px;
  width: 100%;

  z-index: 3;
  color: #bbbbbb;
  font-size: 24px;
  text-align: center;

  span {
    display: inline-block;
    color: #54afff;
  }
}

::v-deep .van-cell {
  background: none;
  padding: 30px 15px;
  border-bottom: 1px solid #fff;
}

::v-deep .van-cell::after {
  border-bottom: none;
}

::v-deep .van-field__control {
  color: #fff;
}

::v-deep .van-field--error .van-field__control::placeholder {
  font-size: 0;
}

// ::v-deep .van-field__error-message {
//   margin-top: 20px;
// }
::v-deep .van-field__control::placeholder {
  color: #fff;
}

::v-deep .van-button--normal {
  font-size: 34px;
}
</style>
